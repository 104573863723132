import { Card } from "@radix-ui/themes";
import React from "react";

export default function ScenarioSizedCard({
  children,
  onClick,
}: {
  onClick?: () => void;
  children: React.ReactNode;
}) {
  return (
    <Card
      asChild
      className={"w-[20rem] lg:h-[25rem] h-[30rem]"}
      tabIndex={0}
      variant={"surface"}
    >
      {children}
    </Card>
  );
}
