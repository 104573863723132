import { FrontendScenario } from "@/backend/frontendScenario";
import { Ref, memo } from "react";
import { useTextFit } from "use-simple-text-fit";
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Strong,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import ScenarioSizedCard from "@/pages/app/home/scenarioSizedCard";
import { useRouterUtils } from "@/app/routerUtils";
import { trpc } from "@/utils/trpc";
import { imageKitLoader } from "@/pages/app/home/imageKitLoader";
import Image from "next/image";
import { BsBook } from "react-icons/bs";
import { ImQuill } from "react-icons/im";
import { CharacterAvatarRow } from "@/pages/app/home/characterAvatarRow";
import { usePostHog } from "posthog-js/react";
import { BiLink } from "react-icons/bi";
import { toast } from "sonner";
import { useUserProfile } from "@/utils/auth/useUserProfile";
import { useAnalytics } from "@/analyticsContext";

export const ScenarioCard = memo(function ScenarioCard({
  scenario,
  loadEarly,
  onClicked,
}: {
  scenario: FrontendScenario;
  loadEarly: boolean;
  onClicked?: () => void;
}) {
  const [ref, fit] = useTextFit({ min: 12, max: 24 }, [
    scenario,
    scenario.name,
  ]);
  const { userProfile } = useUserProfile();
  const routerUtils = useRouterUtils();
  const createStory =
    trpc.conversations.createConversationWithScenario.useMutation({
      onSuccess: async ({ id }) => {
        await routerUtils.openStory(id);
      },
    });
  const posthog = usePostHog();
  const analytics = useAnalytics();
  return (
    <ScenarioSizedCard>
      <Button
        onClick={() => {
          analytics.capture("ui.scenarioCardClicked", {
            scenarioId: scenario.id,
            scenarioName: scenario.name,
          });
          onClicked?.();
          createStory.mutate({
            scenarioId: scenario.id,
          });
        }}
      >
        <Image
          loader={imageKitLoader}
          fill
          alt={
            scenario.description ?? `An image for the scenario ${scenario.name}`
          }
          loading={loadEarly ? "eager" : "lazy"}
          src={scenario.backdropImage!}
          className="opacity-50"
          sizes="400px, (max-width: 768px) 500px, (max-width: 1200px) 600px"
          style={{
            objectFit: "cover",
          }}
        />
        <CharacterAvatarRow
          size="180px"
          enableShortcut={true}
          scenario={scenario}
          loadEarly={loadEarly}
        />
        <Badge
          size={"3"}
          radius={"large"}
          className={"border-2 border-black"}
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
          }}
          variant={"solid"}
        >
          <Flex justify={"center"} align={"center"} gap={"1"}>
            <Box height={"10px"} overflow={"clip"}>
              <ImQuill height={"10px"} />
            </Box>
            <Strong>{Number(scenario.wordCount).toLocaleString()} words</Strong>
          </Flex>
        </Badge>

        <Flex
          direction={"column"}
          gap={"4"}
          p={"4"}
          className={"backdrop-blur-lg flex-col justify-between shadow-xl"}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            boxSizing: "border-box",
            width: "100%",
            height: "55%",
            zIndex: 10,
            backgroundColor: "rgba(0,0,0,.7)",
            transform: "translateZ(0)",
          }}
        >
          <Flex className={"flex-col"}>
            <Flex align={"center"} gap="2">
              <Heading
                style={{
                  color: "var(--white-a11)",
                  height: "2.1rem",
                }}
                data-dark={"true"}
                className={"line-clamp-1"}
                ref={ref as Ref<HTMLHeadingElement>}
              >
                {scenario.name}
              </Heading>
              {scenario.isAdult && (
                <Tooltip content={"Not Spellbound Approved"}>
                  <div className="w-2 h-2 bg-red-500 rounded-full ml-1" />
                </Tooltip>
              )}
              {(!!userProfile?.isAdmin || !scenario.isAdult || true) && (
                <IconButton
                  asChild
                  onClick={(e) => {
                    analytics.capture("ui.copyScenarioLink", {
                      scenarioId: scenario.id,
                      scenarioName: scenario.name,
                    });
                    navigator.clipboard.writeText(
                      routerUtils.urlForScenario(scenario.id),
                    );
                    toast.success("Link copied to clipboard");
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  variant="soft"
                  style={{ zIndex: 10 }}
                >
                  <BiLink size={"1.5rem"} />
                </IconButton>
              )}
            </Flex>
            <Text
              className={"line-clamp-4 dark"}
              style={{
                color: "var(--white-a11)",
              }}
              size={{ initial: "2", sm: "2" }}
            >
              {scenario.description}
            </Text>
          </Flex>
          <Button
            asChild
            size={"4"}
            variant={"surface"}
            className={"mt-4"}
            style={{
              backgroundColor: "var(--gray-3)",
              transform: "translateZ(0)",
              padding: "0.75rem 2rem",
              position: "relative",
              marginTop: "auto",
              cursor: "pointer",
            }}
          >
            <Flex
              className={"flex-row"}
              maxWidth={"100%"}
              align={"center"}
              gap={"2"}
            >
              <Spinner loading={createStory.isPending}>
                <BsBook />
              </Spinner>
              <span
                className={
                  "line-clamp-1 inline-block truncate max-w-full text-ellipsis"
                }
              >
                {scenario.callToAction}
              </span>
            </Flex>
          </Button>
        </Flex>
      </Button>
    </ScenarioSizedCard>
  );
});
