import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Badge,
  Box,
  Card,
  Flex,
  Heading,
  Strong,
  Text,
  Tooltip,
  Skeleton,
} from "@radix-ui/themes";
import { FrontendCharacter } from "@/pages/app/home/frontendCharacter";
import Link from "next/link";
import { imageKitLoader } from "@/pages/app/home/imageKitLoader";
import Image from "next/image";
import { usePostHog } from "posthog-js/react";
import { LuMessagesSquare } from "react-icons/lu";

import { memo } from "react";
import { useAnalytics } from "@/analyticsContext";
import { GiQuill } from "react-icons/gi";
import { BiSolidMessageSquareMinus } from "react-icons/bi";

export const CharacterCard = memo(
  function CharacterCard({
    character,
    onClick,
    loading = false,
  }: {
    character: FrontendCharacter;
    onClick?: () => void;
    loading?: boolean;
  }) {
    const [isTransparent, setIsTransparent] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const analytics = useAnalytics();

    // useEffect(() => {
    //   if (imgRef.current?.complete) {
    //     checkImageTransparency(imgRef.current);
    //   }
    // }, [checkImageTransparency]);

    useEffect(() => {
      if (isTransparent) {
        console.log(character.name + " is transparent");
      }
    }, [isTransparent, character.name]);
    const slug = character.name?.toLowerCase()?.replace(/ /g, "-");
    const posthog = usePostHog();
    const traits = character.traits
      ?.sort((a, b) => {
        if (a.length === b.length) {
          return a.localeCompare(b);
        }
        return a.length - b.length;
      })
      .slice(0, 3);
    const wordCountString = character.wordCount
      ? character.wordCount >= 1000000
        ? `${(character.wordCount / 1000000).toFixed(1)}M`
        : character.wordCount >= 1000
          ? `${(character.wordCount / 1000).toFixed(1)}k`
          : character.wordCount
      : "<100";

    const containerRef = useRef<HTMLDivElement>(null);
    const badgeRefs = useRef<HTMLElement[]>([]);
    const [hiddenBadges, setHiddenBadges] = useState(0);
    useEffect(() => {
      const checkOverflow = () => {
        const container = containerRef.current;
        if (!container) return;

        const containerRect = container.getBoundingClientRect();
        const containerWidth = containerRect.right - 50; // Subtracting 50 for padding/margin

        // Make all badges visible initially
        badgeRefs.current.forEach((badge) => {
          if (!badge) return;
          badge.style.visibility = "visible";
          badge.style.display = "inline-block";
        });

        // Determine which badges to hide by checking individual rects
        let accumulatedWidth = 0;
        let hiddenBadges = 0;
        badgeRefs.current.forEach((badge, index) => {
          if (!badge) return;

          const badgeRect = badge.getBoundingClientRect();
          accumulatedWidth += badgeRect.width;

          if (badgeRect.right > containerWidth) {
            badge.style.visibility = "hidden";
            badge.style.display = "none";
            hiddenBadges++;
          } else {
            badge.style.visibility = "visible";
            badge.style.display = "inline-block";
          }
        });
        setHiddenBadges(hiddenBadges);
      };
      checkOverflow();
      window.addEventListener("resize", checkOverflow);
      return () => window.removeEventListener("resize", checkOverflow);
    }, [traits]);

    const totalMissingBadges =
      hiddenBadges + ((character.traits?.length ?? 0) - 3);
    return (
      <div
        variant={"classic"}
        className={
          "group w-[11rem] h-80 md:h-36  md:min-w-80 rounded-md border-2 border-[var(--gray-4)] bg-[var(--gray-2)] dark:bg-[var(--gray-2)]"
        }
        asChild
        style={{
          position: "relative",
          margin: 0,
          padding: 0,
          transform: "translateZ(0)",
          overflow: "hidden",
          pointerEvents: loading ? "none" : "auto",
        }}
        ref={containerRef}
      >
        <Link
          href={`/app/characters/${character?.id}/${slug}`}
          onClick={() => {
            onClick?.();
            posthog.capture("character_clicked", {
              characterId: character.id,
              characterName: character.name,
            });
            analytics.capture("ui.characterCardClicked", {
              characterId: character.id,
              characterName: character.name ?? null,
            });
          }}
        >
          <Flex width={"100%"} height={"100%"} className="flex-col md:flex-row">
            <Box
              className={
                "w-full md:w-32 h-40 md:h-full relative overflow-hidden"
              }
              style={{
                objectFit: "cover",
                objectPosition: "top",
                flexShrink: 0,
                backgroundColor: "transparent",
              }}
            >
              <Skeleton loading={loading}>
                <Image
                  src={character?.avatarUrlHd}
                  alt={character?.altText}
                  loader={imageKitLoader}
                  fill
                  className={`transition-transform duration-1000 ease-in-out group-hover:scale-110 ${
                    loading ? "hidden" : ""
                  }`}
                  style={{
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  sizes="100px, (max-width: 768px) 200px, (max-width: 1200px) 200px"
                />
              </Skeleton>
            </Box>

            <Flex
              gap={{ initial: "1", md: "1" }}
              justify={"between"}
              height={"100%"}
              px={{ initial: "2", md: "3" }}
              py={"2"}
              direction={"column"}
              width={"100%"}
              align="start"
            >
              <Flex
                align={"center"}
                justify={"between"}
                width={"100%"}
                gap={"1"}
              >
                <Flex align={"center"} gap={"1"}>
                  <Skeleton loading={loading}>
                    <Heading size={{ initial: "2", md: "2" }}>
                      {character?.name}
                    </Heading>
                  </Skeleton>
                  {character.isAdult && (
                    <Tooltip content={"Not Spellbound Approved"}>
                      <div className="w-2 h-2 max-w-2 max-h-2 bg-red-500 rounded-full ml-1" />
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
              <Skeleton loading={loading}>
                <Badge
                  color="amber"
                  className="capitalize border-2 border-black"
                  variant={"solid"}
                  mr="auto"
                >
                  <Strong> {wordCountString}</Strong> <LuMessagesSquare />
                </Badge>
              </Skeleton>
              <Skeleton loading={loading}>
                <Text
                  size="1"
                  color="gray"
                  className={"line-clamp-3"}
                  style={{
                    mask:
                      "linear-gradient(to bottom, rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 90%, rgba(0,0,0, 0.5) 99%, rgba(0,0,0, 0.9) 0\n" +
                      "  ) 100% 99% / 100% 100% repeat-x",
                  }}
                >
                  {character?.description}
                </Text>
              </Skeleton>
              <Flex gap="1" className="overflow-visible" pb="2">
                {traits.map((trait, index) => (
                  <Skeleton loading={loading}>
                    <Badge
                      key={trait}
                      color="orange"
                      ref={(el) => (badgeRefs.current[index] = el!)}
                      className="capitalize font-bold"
                      variant="outline"
                    >
                      {trait}
                    </Badge>
                  </Skeleton>
                ))}
                {totalMissingBadges > 0 && (
                  <Skeleton loading={loading}>
                    <Badge size="1" color="gray">
                      +{totalMissingBadges}
                    </Badge>
                  </Skeleton>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Link>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.character.id === nextProps.character.id &&
      prevProps.onClick === nextProps.onClick
    );
  },
);
